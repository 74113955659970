<template>
  <div class="mt-3">
    <button @click="backClicked" class="btn btn-grey-light d-flex align-items-center p-0 pb-3"><span class="material-icons mr-2">arrow_left</span> Back</button>

    <div class="form-group">

          <p><label for="" class=""><small><strong>Waypoint type</strong></small></label></p>
          <div class="input-group mb-3 text-muted">
            <select class="custom-select text-muted w-100" id="waypointType" v-model="selectedWaypoint.type">
              <option v-for="waypointType in allWaypointTypes" :key="waypointType.poi" :value="waypointType.poi">
                {{ waypointType.prettyName() }}
              </option>
            </select>
          </div>
      <hr class="my-3">

          <p><label for="plannedTrailName" class=""><small><strong>Name</strong></small></label></p>
          <input type="text" v-model="title" class="form-control mb-3" id="plannedTrailName"
                 placeholder="Enter name">

          <hr class="my-3">

          <p><label for="plannedTrailDesc"><small><strong>Description</strong></small></label></p>
          <textarea class="form-control mb-3" id="plannedTrailDesc" v-model="description" placeholder="Some details about the waypoint"></textarea>

          <hr class="my-3">

          <p><label for="plannedTrailImg" class="form-label"><small><strong>Add images</strong></small></label></p>
          <form class="custom-file">
            <button class="btn btn-secondary w-100" @click="triggerFileInput($event)">Choose Files</button>
            <input
              multiple="multiple" ref="fileInput" accept=".jpg, .jpeg, .png" id="plannedTrailImg"
              type="file" class="custom-file-upload w-100" style="display: none;" @change="handleFileInputChange"
            />
            <div class="thumbnails mt-2 mb-2">
              <div v-for="(previewObj, index) in previewUrls" :key="index" class="thumbnail square">
                <img :src="previewObj.file.url" :alt="`image-${index}`" class="thumbnail-image" />
                <button @click="removeImage($event, previewObj)" class="delete-button btn-secondary">x</button>
              </div>
            </div>
          </form>

          <hr class="my-3">

          <p><label for="plannedTrailImg" class="form-label"><small><strong>Group</strong></small></label></p>
          <form class="custom-file">
            <button class="btn btn-secondary w-100" @click="chooseGroupClicked($event)">Choose Group</button>
          </form>
          <hr class="my-3">


          <p><label for="" class=""><small><strong>Who can view</strong></small></label></p>
          <div class="input-group mb-3 text-muted">
            <select class="custom-select text-muted w-100" id="plannedTrailPrivacy" @change="privacyChanged">
              <template v-if="selectedWaypoint.public">
                <option value="1" selected>Everyone can view</option>
                <option value="2">Only I can view</option>
              </template>
              <template v-else>
                <option value="1">Everyone can view</option>
                <option value="2" selected>Only I can view</option>
              </template>
            </select>
          </div>

          <hr class="my-3">

          <div class="d-flex">
            <button class="btn btn-secondary flex-fill mr-2 mt-3" data-bs-dismiss="modal" @click="cancel">CANCEL
            </button>
            <button class="btn btn-primary flex-fill mt-3" @click="save">SAVE</button>
          </div>
        </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { POIUtility } from '../../utils/models/POI';
import {WaypointOptionsDialogPages} from "../../utils/models/WaypointOptionsDialogPages";

export default defineComponent({
  data() {
    return {
      allWaypointTypes: [],
    };
  },
  mounted() {
    this.allWaypointTypes = POIUtility.allPOIUtils();
  },
  computed: Object.assign(mapState({
    selectedWaypoint: (state) => state.waypoints.selectedWaypoint,
  }), {
    title: {
      get() {
        return this.selectedWaypoint.title;
      },
      set(newValue) {
        this.$store.commit('waypoints/setSelectedWaypointTitle', newValue);
      },
    },
    description: {
      get() {
        return this.selectedWaypoint.description;
      },
      set(newValue) {
        this.$store.commit('waypoints/setSelectedWaypointDescription', newValue); // Example of using Vuex to set a value.
      },
    },
    previewUrls() {
      return this.selectedWaypoint.waypointPhotos;
    },
  }),
  methods: {
    backClicked() {
      this.$store.commit('waypoints/popPage');
    },
    privacyChanged() {
      this.selectedWaypoint.public = !this.selectedWaypoint.public;
    },
    save() {
      this.$store.dispatch('waypoints/saveSelectedWaypoint');
    },
    cancel() {
      $('#add-to-map-dialog').modal('hide');
    },
    triggerFileInput(event) {
      event.preventDefault();
      this.$refs.fileInput.click(); // Trigger the click event on the file input
    },
    removeImage(event, image) {
      event.preventDefault();
      this.$store.dispatch('waypoints/provisionallyRemoveWaypointImage', image);
    },
    async handleFileInputChange(event) {
      const { files } = event.target;
      if (files.length > 0) {
        this.$store.dispatch('waypoints/uploadWaypointImages', files);
      }
    },
    chooseGroupClicked(event) {
      event.preventDefault();
      this.$store.commit('waypoints/setTopPage', WaypointOptionsDialogPages.WaypointGroupSelection);
    },
  },
});
</script>

<style scoped>
.thumbnails .thumbnail{
  max-width: 70px;
  display: inline-flex;
  margin-right: 10px;
  position: relative;
}

.thumbnail .delete-button{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DB2B35;
}
</style>
