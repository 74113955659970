<template>
  <div>
    <div class="options-list mt-3">
      <div
        class="option-item"
        v-for="(label, source) in trailOptions"
        :key="source"
      >
        <!-- Hidden checkbox for accessibility -->
        <input
          type="checkbox"
          :id="`toggle-${source}`"
          :checked="isSourceActive(source)"
          @change="toggleTrail(source)"
          style="display: none"
        />

        <!-- Label that now includes the SVG toggle -->
        <label
          class="custom-checkbox d-flex justify-content-between"
          role="switch"
          :aria-checked="isSourceActive(source)"
          :for="`toggle-${source}`"
        >
          <span class="label-text">{{ label }}</span>
          <!-- SVG toggle switch element -->
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="21" viewBox="0 0 42 21" fill="none">
            <rect
              width="42"
              height="21"
              rx="10.5"
              :fill="sourceColor(source)"
            />
            <circle
              class="switch-circle"
              :class="{ 'switch-circle-active': isSourceActive(source) }"
              cx="11.5"
              cy="10.5"
              r="8.5"
              fill="white"
            />
          </svg>
        </label>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'TrailOptions',
  computed: {
    ...mapState('mapDataTrails', ['trailOptions', 'sourceColors']),
    ...mapGetters('mapDataTrails', ['isSourceActive']),
  },
  methods: {
    ...mapActions('mapDataTrails', ['toggleTrail', 'initActiveSources', 'initMapClickHandler', 'refreshLineSources']),
    // Returns the proper color based on the active state
    sourceColor(source) {
      return this.isSourceActive(source)
        ? this.sourceColors[source]
        : '#CCC';
    },
  },
  beforeDestroy() {
    window.bus.off('map-loaded');
  },
  created() {
    window.bus.$on('map-loaded', () => {
      this.initActiveSources();
      this.initMapClickHandler();
      window.map.on("style.load", () => {
        this.refreshLineSources();
      });
    });
  },
};
</script>

<style scoped>
.options-list {
  padding: 0;
  margin: 0;
}

.option-item {
  padding: 10px 0;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Hide the native checkbox but keep it accessible */
.custom-checkbox input {
  display: none;
}

/* Text label styling */
.label-text {
  font-size: 1em;
  font-weight: 500;
}

/* Circle transition styling for the SVG toggle */
.switch-circle {
  transition: transform 0.3s ease;
}

.switch-circle-active {
  /* Shift the circle to represent an active state */
  transform: translateX(19px);
}
</style>
