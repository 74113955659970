<template>
  <div id="edit-recording-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-5">
          <div class="import-dialog">
            <header>
              <h5 class="mb-3"><strong>Edit Recording Details</strong></h5>
            </header>
            <form>
              <div class="form-group mb-3">
                <label class="mb-2 text-muted" for="title"><small>Title</small></label>
                <input type="text" class="form-control" v-model="title" placeholder="Name this recording">
              </div>
              <div class="form-group mb-3">
                <label class="mb-2 text-muted" for="body"><small>Add a description</small></label>
                <textarea class="form-control" v-model="body" rows="3" placeholder="Enter description"></textarea>
              </div>
              <div class="form-group mb-3">
                <label class="mb-2 text-muted" for="date">Change Date</label>
                <input type="datetime-local" class="form-control" v-model="dateTime">
              </div>
              <button type="button" class="btn btn-primary" @click="updateRecording">Save Changes</button>
              <button type="button" class="btn btn-secondary ml-2" data-bs-dismiss="modal">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <LoadingDialog :show-loading-dialog="loading"/>
  </div>
</template>

<script lang="ts">
import graph_client from '../utils/graphql/graph_client';
import { GetRecordedTrailById, UpdateRecording } from '../utils/graphql/recorded-trail-graph';
import LoadingDialog from '../utils/shared_components/LoadingDialog.vue';

export default {
  components: { LoadingDialog },
  data() {
    return {
      title: [],
      body: '',
      dateTime: null,
      recordingId: null,
      loading: false,
    };
  },
  mounted() {
    window.showEditRecordingModal = (recordingId) => {
      this.loading = true;
      this.recordingId = recordingId;
      this.getRecordingDetails();
      $('#edit-recording-modal').modal('show');
    };
  },
  methods: {
    getRecordingDetails() {
      graph_client.request(GetRecordedTrailById, { input: this.recordingId }).then((data) => {
        this.title = data.recording.title;
        this.body = data.recording.body;
        const date = new Date(data.recording.createdAt);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);  // getMonth is zero-based, add one to get correct month
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        this.dateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
        this.loading = false;
      });
    },
    updateRecording() {
      this.loading = true
      const formattedDate = new Date(this.dateTime).toISOString();
      const input = {
        id: this.recordingId,
        title: this.title,
        body: this.body,
        createdAt: formattedDate,
      };
      graph_client.request(UpdateRecording, { input }).then(() => {
        this.loading = false;
        this.resetValues();
        window.reloadRecordedTrails();
        $('#edit-recording-modal').modal('hide');
      }).catch((error) => console.error('Error updating recording:', error));
    },
    resetValues() {
      this.title = '';
      this.body = '';
      this.dateTime = null;
      this.recordingId = null;
    },
  },
};
</script>

<style scoped>

.file-drop-area {
  border: 2px dashed var(--gray-border);
  padding: 10px;
  text-align: center;
  margin: 20px 0;
}

.file-list {
  list-style-type: none;
  padding: 0;
}

.file-info {
  margin-bottom: 10px;
}

button {
  cursor: pointer;
}

.loading {
  color: #555;
}
</style>
