<template>
  <div>
    <!-- Search Filters -->
    <div class="row">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>

    <div class="row d-none d-md-flex">
      <div class="col-3"><small class="text-muted"> Search by text</small></div>
      <div class="col-3"><small class="text-muted"> Search by date range</small></div>
      <div class="col-3"></div>
      <div class="col-3"></div>
    </div>

    <div class="row d-block d-md-none">
      <div class="col-12"><small class="text-muted"> Filter routes</small></div>
    </div>

    <div class="row">
      <div class="col-12 col-md-3 my-2">
        <input class="form-control" type="text" v-model="searchString" placeholder="Name or description" />
      </div>

      <div class="col-12 col-md-3 my-2">
        <input class="form-control" type="date" v-model="startDate" placeholder="Start Date" />
      </div>
      <div class="col-12 col-md-3 my-2">
        <input class="form-control" type="date" v-model="endDate" placeholder="End Date" />
      </div>

      <div class="col-12 col-md-3 my-2">
        <button class="btn btn-primary btn-sm" @click="searchRecordedTrailsClicked($event, true)">Search</button>
        <button class="btn btn-text btn-sm" @click="clearSearchFilters($event)">Clear</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>

    <div class="bg-dark p-3 rounded-lg">
      <div v-if="recordedTrails.length">
        <div v-for="(trail, index) in recordedTrails" :key="trail.id || index" class="row">
          <div class="col-12 col-md-2 d-flex align-items-center justify-content-center">
            <img
              :src="trail.thumbnailUrl"
              class="rounded img-fluid"
              width="176"
              height="98"
              alt="Recorded Trail Thumbnail"
            />
          </div>

          <div class="col-12 col-md-5 mt-2 d-flex align-items-center">
            <div>
              <h3 class="mb-0 display-5">
                <b>{{ trail.title || 'Untitled Recorded Trail' }}</b>
              </h3>
              <p>
                <small class="text-muted">Created: {{ trail.createdAt }}</small>
              </p>
              <p>
                <small v-if="trail.original_trail">
                <span class="text-info">
                  &middot; Recorded from
                  <u>
                    <a :href="trail.original_trail.path">{{ trail.original_trail.title }}</a>
                  </u>
                </span>
                </small>
              </p>
            </div>
          </div>

          <div class="col-12 col-md-4 d-flex align-items-center justify-content-center" v-if="formMode">
            <button @click="selectRecordedTrail($event, trail)" class="btn btn-sm btn-primary d-flex float-right justify-content-center rounded mx-1 my-0 h-100">
              SELECT
            </button>
          </div>
          <div class="col-12 col-md-4 d-flex align-items-center my-2" v-else>
            <div class="w-100 d-flex justify-content-center">
              <a
                :href="trail.shareableUrl"
                class="btn btn-sm btn-secondary d-flex align-items-center justify-content-center rounded mx-1 my-0 h-100"
              >
                <span class="material-icons">map</span>
              </a>
              <a
                :href="printRecordedTrail(trail)"
                class="btn btn-sm btn-secondary d-flex align-items-center justify-content-center rounded mx-1 my-0 h-100"
              >
                <span class="material-icons">print</span>
              </a>
              <button
                @click="editRecordedTrail(trail)"
                class="btn btn-sm btn-secondary d-flex align-items-center justify-content-center rounded mx-1 my-0 h-100"
              >
                <span class="material-icons">edit</span>
              </button>
              <button
                @click="triggerCopyModel(trail)"
                class="btn btn-sm btn-secondary d-flex align-items-center justify-content-center rounded mx-1 my-0 h-100 openCopyModalButton"
              >
                <span class="material-icons">share</span>
              </button>
              <button
                @click="deleteRecordedTrail(trail)"
                class="btn btn-sm btn-secondary d-flex align-items-center justify-content-center rounded mx-1 my-0 h-100"
              >
                <span class="material-icons">delete</span>
              </button>
            </div>
          </div>
          <div class="col-12">
            <hr class="my-3" />
          </div>
        </div>
      </div>
      <div v-else>
        <p>No trails found.</p>
      </div>

      <!-- "See More" Button -->
      <div v-if="hasMoreTrails" class="see-more d-flex justify-content-center m-0">
        <button class="btn btn-link" @click="searchRecordedTrailsClicked($event, false)">See More</button>
      </div>
      <LoadingDialog :show-loading-dialog="loading" />

      <div id="copyRecordedModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
              <h3 class="modal-title m-0">
                <strong>Share</strong>
              </h3>
              <button class="close btn-link" data-bs-dismiss="modal" aria-label="Close">
                <span>×</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="mb-1">
                <strong>Trail Link</strong>
                <span class="text-yellow" style="display: none">(Copied!)</span>
              </p>
              <p class="mb-3"><small class="text-muted">Anyone with this link can view your trail</small></p>

              <div class="input-group mb-3" @click="copyToClipboard">
                <input id="recordedTrailShareInput" class="form-control" type="text">
                <button class="btn btn-primary input-group-text" type="button">
                  <span class="material-icons">content_copy</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditRecordingDetails />
  </div>
</template>

<script lang="ts">
import graph_client from '../utils/graphql/graph_client';
import LoadingDialog from "../utils/shared_components/LoadingDialog.vue";
import {DestroyRecording, SearchUsersRecordedTrails, UserRecordingsQuery } from "../utils/graphql/recorded-trail-graph";
import EditRecordingDetails from "../edit_recording_details/EditRecordingDetails.vue";

export default {
  name: 'RecordedTrails',
  components: { LoadingDialog, EditRecordingDetails },
  props: {
    formMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recordedTrails: [],
      searchString: '',
      startDate: null,
      endDate: null,
      currentPage: 1,
      pageSize: 10,
      hasMoreTrails: false,
      loading: true,
    };
  },
  mounted() {
    this.searchRecordedTrails(true);
    window.reloadRecordedTrails = () => {
      this.searchRecordedTrails(true);
    };
  },
  methods: {
    selectRecordedTrail(event, trail) {
      event.preventDefault();
      window.trailSelected(trail.id);
      window.trailSelectedName(trail.title);
      window.closeVueModal();
    },
    searchRecordedTrailsClicked(event, reset = false) {
      event.preventDefault();
      this.searchRecordedTrails(reset);
    },
    searchRecordedTrails(reset = false) {
      this.loading = true;
      if (reset) {
        this.currentPage = 1;
        this.recordedTrails = [];
      }

      const variables = {
        string: this.searchString || '',
        startDate: this.startDate || null,
        endDate: this.endDate || null,
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      graph_client.request(SearchUsersRecordedTrails, variables).then((data) => {
        const newTrails = data.recordingsTextSearch || [];
        this.loading = false;

        this.recordedTrails = reset ? newTrails : this.recordedTrails.concat(newTrails);
        this.hasMoreTrails = newTrails.length === this.pageSize;

        if (this.hasMoreTrails) {
          this.currentPage += 1;
        }
      }).catch((error) => {
        console.error('Error fetching recorded trails:', error);
      });
    },
    clearSearchFilters(event) {
      event.preventDefault();
      this.searchString = '';
      this.startDate = null;
      this.endDate = null;
      this.searchRecordedTrails(true);
    },
    editRecordedTrail(trail) {
      window.showEditRecordingModal(trail.id);
    },
    printRecordedTrail(trail) {
      return `/print-maps?data_ids[]=${trail.id}&data_types[]=Recording`;
    },
    triggerCopyModel(trail) {
      const trailUrl = trail.shareableUrl;
      document.getElementById('recordedTrailShareInput').value = trailUrl;
      $('#copyRecordedModal').modal('show');
    },
    deleteRecordedTrail(trail) {
      if (confirm('Are you sure you want to delete this recorded trail?')) {
        this.loading = true;
        graph_client.request(DestroyRecording, {
          input: { id: trail.id },
        }).then(() => {
          this.searchRecordedTrails(true);
        }).catch((error) => {
          console.error('Error deleting recorded trail:', error);
        });
      }
    },
    copyToClipboard() {
      const inputElement = document.getElementById('recordedTrailShareInput');
      if (inputElement) {
        inputElement.select();
        inputElement.setSelectionRange(0, 99999);

        try {
          navigator.clipboard.writeText(inputElement.value).then(() => {
            const confirmationElement = inputElement.parentElement.querySelector('.text-yellow');
            if (confirmationElement) {
              confirmationElement.style.display = 'inline';
              setTimeout(() => {
                confirmationElement.style.display = 'none';
              }, 2000);
            }
          });
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      }
    }
  },
};
</script>

<style scoped>
.see-more {
  margin-top: 20px;
}

.see-more button {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
