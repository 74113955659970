<template>
  <div>
    <template v-if="topPageIsWaypointOptions">
      <WaypointDialogOptions/>
    </template>
    <template v-if="topPageIsWaypointGroups">
      <WaypointDialogWaypointGroups/>
    </template>
    <template v-if="topPageIsWaypointGroupsWaypoints">
      <WaypointDialogWaypointGroupWaypoints/>
    </template>
    <template v-if="topPageIsWaypointViewDetails">
      <WaypointDialogViewWaypointDetails/>
    </template>
    <template v-if="topPageIsWaypointEditDetails">
      <WaypointDialogEditWaypointDetails/>
    </template>
    <template v-if="topPageIsWaypoints">
      <WaypointDialogWaypoints/>
    </template>
    <template v-if="topPageIsWaypointGroupSelection">
      <WaypointDialogWaypointGroupSelection/>
    </template>
    <LoadingDialog :show-loading-dialog="loading"/>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { mapGetters, mapState } from 'vuex';
import LoadingDialog from '../utils/shared_components/LoadingDialog.vue';
import WaypointDialogEditWaypointDetails from './components/WaypointDialogEditWaypointDetails.vue';
import WaypointDialogOptions from './components/WaypointDialogOptions.vue';
import WaypointDialogViewWaypointDetails from './components/WaypointDialogViewWaypointDetails.vue';
import WaypointDialogWaypointGroups from './components/WaypointDialogWaypointGroups.vue';
import WaypointDialogWaypointGroupSelection from './components/WaypointDialogWaypointGroupSelection.vue';
import WaypointDialogWaypointGroupWaypoints from './components/WaypointDialogWaypointGroupWaypoints.vue';
import WaypointDialogWaypoints from './components/WaypointDialogWaypoints.vue';

export default defineComponent({
  components: {
    LoadingDialog,
    WaypointDialogWaypoints,
    WaypointDialogWaypointGroupWaypoints,
    WaypointDialogWaypointGroups,
    WaypointDialogEditWaypointDetails,
    WaypointDialogViewWaypointDetails,
    WaypointDialogOptions,
    WaypointDialogWaypointGroupSelection,
  },
  mounted() {

  },
  computed: Object.assign(mapGetters({
    topPageIsWaypointOptions: 'waypoints/topPageIsWaypointOptions',
    topPageIsWaypointViewDetails: 'waypoints/topPageIsWaypointViewDetails',
    topPageIsWaypointEditDetails: 'waypoints/topPageIsWaypointEditDetails',
    topPageIsWaypoints: 'waypoints/topPageIsWaypoints',
    topPageIsWaypointGroups: 'waypoints/topPageIsWaypointGroups',
    topPageIsWaypointGroupsWaypoints: 'waypoints/topPageIsWaypointGroupsWaypoints',
    topPageIsWaypointGroupSelection: 'waypoints/topPageIsWaypointGroupSelection',
  }), mapState({
    loading: (state) => state.waypoints.loading,
  })),
});
</script>

<style scoped>
  .modal-content{
    overflow-x: hidden !important;
  }
</style>
