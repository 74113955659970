<template>
  <div>
    <div class="px-3 py-2 text-dark" style="background-color: #fab500">
      <p class="m-0"><strong>{{title}}</strong></p>
      <small>{{formattedDate}}</small>
    </div>

    <div class="wcarousel-container pt-3 pl-3 pr-0" id="myCarousel" :if="waypointPhotos.length > 0">
      <div class="wcarousel">
          <div v-for="(photo, index) in waypointPhotos" :key="index" class="wcarousel-item square pr-3">
              <img :src="photo.file.url" alt="Image 1">
          </div>
      </div>
  </div>

  <div class="p-4">
    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="planTrailToWaypoint">
      Plan trail from waypoint
      <span class="float-right" @click="planTrailToWaypoint">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 14C14.0899 14 17 11.0899 17 7.5C17 3.91015 14.0899 1 10.5 1C6.91015 1 4 3.91015 4 7.5C4 11.0899 6.91015 14 10.5 14ZM10 3C9.26907 3 8.26245 3.28183 7.43247 4.07861C6.57717 4.8997 6 6.17853 6 8H8C8 6.62147 8.42283 5.9003 8.81753 5.52139C9.23755 5.11817 9.73093 5 10 5V3Z" fill="white"/>
          <path d="M9 13H11V22L9 20.0435V13Z" fill="white"/>
        </svg>
      </span>
    </button>

    <hr class="my-2">

    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="printWaypoint()">
      Print map of waypoint
      <span class="float-right" @click="printWaypoint">
        <span class="material-icons float-right" @click="printWaypoint()">print</span>
      </span>
    </button>

    <template v-if="isUsersWaypoint">
      <hr class="my-2">

      <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="editWaypointDetails($event)" >
        Edit details
        <span class="material-icons float-right" @click="editWaypointDetails($event)">edit</span>
      </button>
    </template>

    <hr class="my-2">

    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="openOnGoogleStreetView">
      View on Google Street View
      <span class="material-icons float-right" @click="openOnGoogleStreetView">directions</span>
    </button>



    <hr class="my-2">

    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="shareClicked">
      Share Waypoint <span class="text-yellow ml-1" v-if="showCopied">Copied to clipboard!</span>
      <span class="material-icons float-right">share</span>
    </button>
    <button class="btn btn-link pt-0" @click="shareClicked">{{ selectedWaypointShareUrl }}</button>
    <hr class="my-2">

    <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="getDirectionsToWaypoint">
      Driving directions
      <span class="material-icons float-right" @click="getDirectionsToWaypoint">directions</span>
    </button>

    <template v-if="isUsersWaypoint">
      <hr class="my-2">

      <button class="btn btn-block btn-link text-white d-flex justify-content-between" @click="deleteWaypoint">
        Delete Waypoint
        <span class="material-icons float-right" @click="deleteWaypoint">delete</span>
      </button>
    </template>

    <hr class="my-2">

    <p class="pt-3 px-2"><strong class="text-yellow">Details</strong></p>
    <p class="pt-2 px-2">
      <span><strong class="">Description:</strong></span>
    </p>
    <p class="pb-2 px-2">
      <span class="">{{ this.description }}</span>
    </p>
    <hr class="my-2">

    <p class="p-2">
      <span><strong class="">Coords:</strong></span>
      <span class="float-right">{{ this.preferenceLonLat }}</span>
    </p>
    <hr class="my-2">

    <p class="p-2">
      <span><strong class="">Type:</strong></span>
      <span class="float-right">{{ prettyType }}</span>
    </p>
    <hr class="my-2">

    <p class="p-2">
      <span><strong class="">ID:</strong></span>
      <span class="float-right">{{ clientId }}</span>
    </p>
    <hr class="my-2">

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary d-block m-auto" data-bs-dismiss="modal">Close</button>
  </div>
</div>
</template>

<script>

import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { GridRefUtils } from '../../utils/helpers/GridRefPreference';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { openGoogleMaps } from '../../utils/helpers/OpenOnGoogleMaps';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { wktPointToLatLng } from '../../utils/helpers/WKTPointToLatLng';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { POIUtility } from '../../utils/models/POI';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { WaypointOptionsDialogPages } from '../../utils/models/WaypointOptionsDialogPages';

export default defineComponent({
  data() {
    return {
      gridRefUtils: null,
      showCopied: false,
    };
  },
  async mounted() {
    const gridRef = await GridRefUtils.preference();
    this.gridRefUtils = gridRef;

    document.addEventListener("DOMContentLoaded", function() {
    const carousel = document.getElementById("myCarousel");

    carousel.addEventListener("wheel", function(event) {
        event.stopPropagation();
    });
});
  },
  computed: Object.assign(mapState({
    selectedWaypoint: (state) => state.waypoints.selectedWaypoint,
  }), mapGetters({
    selectedWaypointShareUrl: 'waypoints/selectedWaypointShareUrl',
  }), {
    id() {
      return this.selectedWaypoint.id;
    },
    title() {
      return this.selectedWaypoint.title;
    },
    type() {
      return this.selectedWaypoint.type;
    },
    prettyType() {
      return POIUtility.fromString(this.type).prettyName();
    },
    description() {
      return this.selectedWaypoint.description;
    },
    lonlat() {
      return this.selectedWaypoint.lonlat;
    },
    preferenceLonLat() {
      return this.gridRefUtils ? this.gridRefUtils.convert(this.point) : this.lonlat;
    },
    point() {
      return wktPointToLatLng(this.lonlat);
    },
    createdAt() {
      return this.selectedWaypoint.createdAt;
    },
    updatedAt() {
      return this.selectedWaypoint.updatedAt;
    },
    clientId() {
      return this.selectedWaypoint.clientId;
    },
    userId() {
      return this.selectedWaypoint.userId;
    },
    isUsersWaypoint() {
      if (window.user == null) {
        return false;
      }
      return this.selectedWaypoint.userId === window.user.id;
    },
    public() {
      return this.selectedWaypoint.public;
    },
    formattedDate() {
      const date = new Date(this.createdAt);
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hours = (`0${date.getHours()}`).slice(-2);
      const minutes = (`0${date.getMinutes()}`).slice(-2);
      const seconds = (`0${date.getSeconds()}`).slice(-2);

      // You can adjust this line to get the date in the format you want
      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    },
    waypointPhotos() {
      return this.selectedWaypoint.waypointPhotos ?? [];
    }
  }),
  methods: {
    openOnGoogleStreetView() {
      let lat = this.point.lat;
      let lng = this.point.lng;
      let heading = 0;
      let pitch = 0;
      let fov = 90;
      let url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&heading=${heading}&pitch=${pitch}&fov=${fov}`;
      window.open(url, '_blank');
    },
    editWaypointDetails(event) {
      event.preventDefault();
      this.$store.commit('waypoints/setTopPage', WaypointOptionsDialogPages.WaypointEditDetails);
    },
    deleteWaypoint() {
      this.$store.dispatch('waypoints/deleteSelectedWaypoint');
    },
    shareClicked() {
      navigator.clipboard.writeText(this.selectedWaypointShareUrl).then(() => {
      }).catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
      this.showCopied = !this.showCopied;
    },
    getDirectionsToWaypoint() {
      openGoogleMaps(this.point.lat, this.point.lng);
    },
    planTrailToWaypoint() {
      this.$store.dispatch('waypoints/planTrailToSelectedWaypoint');
    },
    printWaypoint() {
      window.location.href = `/print-maps?data_ids[]=${this.selectedWaypoint.id}&data_types[]=Waypoint`;
    },
  },
});
</script>

<style scoped>

.wcarousel-container {
    overflow: hidden;
    width: calc(2.5 * 300px); /* Showing 2.5 images, each of 300px */
    max-width: 100%;
}

.wcarousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.wcarousel::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.wcarousel-item {
    flex: 0 0 auto;
    width: 45%;
    scroll-snap-align: start;
    position: relative;
}

.wcarousel-item img {
    width: 100%;
    height: auto;
}

</style>
