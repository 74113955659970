<template>
  <div :class="this.leftColHidden ? 'col-12 bg-primary p-0 h-100 position-relative' : 'col-9 d-none d-md-block bg-primary p-0 m-0 h-100 position-relative'">

    <div class="mapbox m-0 p-0 h-100" id="map">
    </div>

    <MapButtons :button-clicked="buttonClicked" :buttons="buttons"/>
    <MapControls style="position: absolute; bottom: 180px; right: 20px;"/>

    <button class="btn rounded bg-dark px-2 d-flex align-items-center toggleSideBar" @click="toggleMap">
      <template v-if="this.leftColHidden">
        <span class="material-icons">chevron_right</span>
      </template>
      <template v-else>
        <span class="material-icons">chevron_left</span>
      </template>
    </button>

    <div class="bg-rounded d-flex align-items-center coordsBar p-0 mt-5">
      <p class="py-1 px-3 m-0">{{ miniCoordsBar }}</p>
    </div>

    <div v-if="chartData" class="chart-container chart-div"
         @mouseleave="hover = false"
         @mouseenter="hover = true">
      <BaseMapViewerChartTopBar/>
      <BaseMapViewerLineChart :chart-data="chartData"/>
    </div>

  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import { MapOverlayOption, MapOverlayOptions } from '../../utils/models/MapOverlayOptions';
import BaseMapViewerChartTopBar from './BaseMapViewerChartTopBar.vue';
import BaseMapViewerLineChart from './BaseMapViewerLineChart.vue';
import MapButtons from "../../components/MapButtons.vue";
import {RevenueCatUtil} from "../../utils/models/RevenueCatUser";
import {mapGetters} from "vuex";
import {OnBoardingGoal} from "../../utils/models/OnBoardingGoal";
import MapControls from "../../components/MapControls.vue";
import {ThreeDimensionalMapManager} from "../../utils/models/MapStyles";
import {RevenueCatWrapper} from "../../utils/RevenueCatWrapper";

export default defineComponent({
  components: {MapControls, MapButtons, BaseMapViewerChartTopBar, BaseMapViewerLineChart },
  data() {
    return {
      hover: false,
      showChart: true,
      buttons: [
        { id: 0, name: 'Change Map', icon: 'layers', enabled: false, dropIcon: true },
        { id: 1, name: '3D Mode', icon: 'landscape', enabled: false, dropIcon: false },
        {
          id: 5, name: 'Add to Map', icon: 'add_circle', enabled: false, dropIcon: false,
        },
        {
          id: 6, name: 'Print Map', icon: 'layers', enabled: false, dropIcon: true,
        }
      ],
    };
  },
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    async _3dClicked() {
      // eslint-disable-next-line no-underscore-dangle
      const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
      if (isProOrProPlus) {
        this.$store.dispatch('mapStyles/toggle3dMaps');
      } else {
        window.location.href = "/purchases"
      }
    },
    async buttonClicked(id: number) {
      const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
      switch (id) {
        case 0:
          if (window.user) {
            $('#map-styles-dialog').modal('show');
          } else {
            window.onBoardingVue.launchFor(OnBoardingGoal.MapStyles)
          }
          break;
        case 1:
          if (isProOrProPlus) {
            ThreeDimensionalMapManager.toggle3DMode()
          } else {
            window.location.href = "/purchases"
          }
          break;
        case 5:
          if (window.user) {
            $('#add-to-map-dialog').modal('show');
          } else {
            window.onBoardingVue.launchFor(OnBoardingGoal.Waypoints)
          }
          break;
        case 6:
          window.location.href = window.printingUrl;
          break;
      }
    },
  },
  props: {
    leftColHidden: {
      type: Boolean,
      default: false,
    },
    toggleMap: {
      type: Function,
      default: () => {},
    },
    mapStylesDialogIdentifier: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
    },
  },
  computed: Object.assign({
    miniCoordsBar() {
      // @ts-ignore
      return this.$store.state.baseMap.miniCoordsBar;
    },
  }
  ),
  watch: {
    _3dEnabled: function (newVal, oldVal) {
      this.buttons[1].enabled = newVal
    }
  }
});
</script>

<style scoped>

.chart-div {
  bottom: 0;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 100;
  background-color: rgb(0 0 0 / 74%);
}

.coordsBar {
  position: absolute;
  top: 1em;
  left: 1em;
  right: 1em;
  margin: auto;
  width: fit-content;
  background-color: rgb(32 32 32 / 50%);
}

.toggleSideBar {
  position: absolute;
  top: 1em;
  left: 1em;
}

.locationButton {
  position: absolute;
  bottom: 331px;
  left: 1em;
}

@media (max-width: 900px) {
  .toggleSideBar {
    top: 5.5em;
  }
}

</style>
