<template>
  <div class="h-100 mx-auto" v-if="leftColumnViewModel != null">
    <div class="d-block mt-2 p-2">

      <ul class="nav nav-tabs nav-fill mb-3 d-flex d-md-none mb-3">
        <li class="nav-item m-0">
          <a class="nav-link p-1 pb-2 active" href="#">Trail Overview</a>
        </li>
        <li class="nav-item m-0">
          <a class="nav-link p-1 pb-2" href="#" @click="toggleMap">Trail Map</a>
        </li>
      </ul>

      <img class="img-fluid my-3 d-block d-md-none" :src="leftColumnViewModel.thumbnailUrl">
      <p class="mb-3"><small class="text-muted"><strong>Created by:</strong> {{ leftColumnViewModel.creating_user_title }}</small></p>
      <h1 class="h5 mb-1"><strong>{{ leftColumnViewModel.title }}</strong></h1>
      <small class="text-muted">{{ leftColumnViewModel.created_at }}</small>

      <div class="row mt-3 p-0 m-0">

        <template v-for="button of trailButtonTypes">
          <div class="col-4 p-1">
            <a @click="buttonClicked(button)" :style="`background-color: ${button.colorClass()}`" :class="`btn btn-block btn-sm m-auto d-flex align-items-center justify-content-center`"><span class="material-icons text-white">{{button.icon()}}</span></a>
            <small><span class="text-center d-block mt-2"><strong>{{ button.text() }}</strong></span></small>
          </div>
        </template>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row p-0 m-0">
        <div class="col-12 p-0">
          <h4 class=""><strong>Stats</strong></h4>
        </div>
      </div>

      <div class="row p-0 m-0 my-2">
        <div class="col-6 p-0">
          <small> <b class="text-muted">Distance:</b></small>
          <p><strong>{{ leftColumnViewModel.prettyDistance() }}</strong></p>
        </div>
        <div class="col-6 p-0">
          <small> <b class="text-muted">Duration:</b></small>
          <p><strong>{{ prettyElapsedTime }}</strong></p>
        </div>
      </div>

      <div class="row p-0 m-0 my-2">
        <div class="col-6 p-0">
          <small> <b class="text-muted">Elevation gain:</b></small>
          <p><strong>{{ leftColumnViewModel.prettyElevationGain() }}</strong></p>
        </div>
        <div class="col-6 p-0">
          <small> <b class="text-muted">Avg Elevation per KM:</b></small>
          <p><strong>{{ leftColumnViewModel.elevPerKM() }}</strong></p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row p-0 m-0">
        <div class="col-12 p-0">
          <HorizontalScroller/>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">

// @ts-ignore
import {LeftColumnViewModel} from '../../utils/models/LeftColumnViewModel';
import {mapState} from "vuex";
import {defineComponent} from "vue";
import {TrailTypes, TrailTypesUtils} from "../../utils/models/TrailTypes";
import {OverviewButtonTypes, OverviewButtonTypesUtils} from "../../utils/models/OverviewButtonTypes";
import HorizontalScroller from "../../utils/shared_components/HorizontalScroller.vue";
import {HikeTimeUtility} from "../../utils/helpers/HikeTime";
import {SecondsFormatter} from "../../utils/helpers/SecondsFormatter";

export default defineComponent( {
  components: {HorizontalScroller},
  props: {
    toggleMap: {
      type: Function,
      required: true,
    },
  },
  computed: {
    trailTypeUtils() {
      return new TrailTypesUtils(this.leftColumnViewModel.trailType)
    },
    actionUrl(): string {
      return window.userLoggedIn ? this.leftColumnViewModel.actionUrl! : '/login';
    },
    actionTitle(): string {
      return window.userLoggedIn ? this.leftColumnViewModel.actionTitle! : 'Login to save';
    },
    ...mapState({
      leftColumnViewModel: (state: any) => state.baseMap.leftColumnViewModel,
    }),
    trailButtonTypes() {
      return this.trailTypeUtils.buttonTypes(this.$store.state.baseMap.leftColumnViewModel.isOwntrail);
    },
    prettyElapsedTime() {
      const plannedTrailType = TrailTypes.PlannedTrail
      if (this.leftColumnViewModel.trailType == plannedTrailType) {
        let hikeTime = HikeTimeUtility.preference()
        let elevationTime = this.leftColumnViewModel.elevationGainMeters * hikeTime.elevGainMultiplier()
        let distanceTime = this.leftColumnViewModel.distanceMeters * hikeTime.distanceMultiplier()
        let secondsTotalTime = (elevationTime + distanceTime) * 60
        return SecondsFormatter.toHHMMSS(secondsTotalTime)
      } else {
        return this.leftColumnViewModel.prettyElapsedTime();
      }
    },
  },
  methods: {
    downloadGPXClicked() {
      if (this.$store.state.recordedTrail != null) {
        this.$store.dispatch('recordedTrail/downloadGPX')
      } else {
        const gpx = this.leftColumnViewModel.lineString.toGPX()
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gpx));
        element.setAttribute('download', this.leftColumnViewModel.title + '.gpx');
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },
    buttonClicked(buttonType: OverviewButtonTypesUtils) {
      if (window.user == null) {
        window.location.href = '/login';
        return;
      }
      switch (buttonType.overviewButtonType) {
        case OverviewButtonTypes.gpx:
          this.downloadGPXClicked()
          break;
        case OverviewButtonTypes.share:
          const selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = window.location.href;
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);
          alert("Copied to clipboard.")
          break;
        case OverviewButtonTypes.copyToPlanned:
          window.location = this.leftColumnViewModel.actionUrl
          break;
        case OverviewButtonTypes.editTrail:
          window.location = this.leftColumnViewModel.editPath
          break;
      }
    },
  },
  watch: {
    trailType: function (newVal: string, oldVal: string) {
      if (TrailTypesUtils.fromString(newVal)) {
        throw Error('Invalid trail type');
      }
    },
  },
});
</script>

<style scoped>

fiftyPercentHeight {
  height: 50%;
}

</style>
