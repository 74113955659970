<template>
  <div>
    <div class="options-list mt-3">
      <div
        class="option-item"
        v-for="poi in poiList"
        :key="poi.layerName"
      >
        <input
          type="checkbox"
          :id="`toggle-${poi.layerName}`"
          :checked="isPoiActive(poi)"
          @change="togglePoi(poi)"
          style="display: none"
        />

        <label
          class="custom-checkbox d-flex justify-content-between"
          role="switch"
          :aria-checked="isPoiActive(poi)"
          :for="`toggle-${poi.layerName}`"
        >
          <div class="d-flex justify-content-between align-items-center">
            <img
              :src="poi.getPublicImageIcon()"
              :alt="poi.prettyName()"
              class="poi-icon"
              :class="{ selected: isPoiActive(poi) }"
              @click="togglePoi(poi)"
              width="25"
            />
            <span class="ml-2 label-text">{{ poi.prettyName() }}</span>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="21" viewBox="0 0 42 21" fill="none">
            <rect
              width="42"
              height="21"
              rx="10.5"
              :fill="isPoiActive(poi) ? poi.getColor() : '#CCC'"
            />
            <circle
              class="switch-circle"
              :class="{ 'switch-circle-active': isPoiActive(poi) }"
              cx="11.5"
              cy="10.5"
              r="8.5"
              fill="white"
            />
          </svg>
        </label>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';

export default {
  name: 'MapDataPOIsTab',
  computed: {
    ...mapState('mapDataPOIs', ['poiList', 'activatedPois']),
    ...mapGetters('mapDataPOIs', ['isPoiActive']),
  },
  methods: {
    ...mapActions('mapDataPOIs', ['togglePoi', 'initActivePois', 'activateClickHandler', 'refreshActivePois']),
  },
  created() {
    // Initialize the activated POIs (from localStorage or default) and add them to the map.
  },
  beforeDestroy() {
    // Clean up event listener to prevent memory leaks
    window.bus.$off('map-loaded');
    debugger;
  },
  mounted() {
    // Set up your map click handler (if needed), similar to before.
    window.bus.$on('map-loaded', () => {
      this.initActivePois();
      this.activateClickHandler();
      window.map.on('style.load', () => {
        this.refreshActivePois();
      });
    });
  },
};
</script>

<style scoped>
/* Reuse your existing styles */
.options-list {
  padding: 0;
  margin: 0;
}

.option-item {
  padding: 10px 0;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label-text {
  font-size: 1em;
  font-weight: 500;
}

.switch-circle {
  transition: transform 0.3s ease;
}

.switch-circle-active {
  transform: translateX(19px);
}
</style>
