<template>
  <div class="modal fade" style="background-color: rgba(7,7,7,0.36);" id="add-to-map-dialog" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-3">

          <ul class="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="trail-tab" data-bs-toggle="tab" data-bs-target="#trail-tab-pane" type="button" role="tab" aria-controls="trail-tab-pane" aria-selected="true">Trails</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="waypoints-tab" data-bs-toggle="tab" data-bs-target="#waypoints-tab-pane" type="button" role="tab" aria-controls="waypoints-tab-pane" aria-selected="false">Waypoints</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="poi-tab" data-bs-toggle="tab" data-bs-target="#poi-tab-pane" type="button" role="tab" aria-controls="poi-tab-pane" aria-selected="false">POIs</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="trail-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
              <VectorDialog />
            </div>
            <div class="tab-pane fade" id="waypoints-tab-pane" role="tabpanel" aria-labelledby="waypoints-tab" tabindex="0">
              <WaypointDialog />
            </div>
            <div class="tab-pane fade" id="poi-tab-pane" role="tabpanel" aria-labelledby="poi-tab" tabindex="0">
              <POIs />
            </div>
          </div>

          <button
            class="button btn-link btn-block btn-sm text-muted mt-3"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <strong>CLOSE</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VectorDialog from './MapDataTrailsTab.vue';
import WaypointDialog from '../waypoint_dialog/WaypointDialog.vue';
import POIs from "./MapDataPOIsTab.vue";

export default defineComponent({
  components: { VectorDialog, WaypointDialog, POIs },
});
</script>

<style scoped>
.modal-content {
  height: 75vh; /* Set the height of the modal content to 70% of the viewport height */
  overflow-y: auto; /* Add a vertical scroll bar if the content overflows */
}
</style>
