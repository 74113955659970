

.chart-info {
  background-color: #fecf17;
  color: #202020;

  svg {
    font-size: 18px;
  }
}

.chart-text {
  z-index: 9999;
  background: white;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
