<template>
  <div class="add-mode" v-if="enabled">
    <p class="text-yellow d-flex align-items-center"><span class="material-icons mr-2">info</span> <strong>Add waypoint</strong></p>
    <p><small>Select where you wish to add the new waypoint</small></p>
    <button class="btn btn-link d-flex align-items-center float-right" @click="disableAddingWaypointsMode">EXIT <span class="material-icons ml-2">close</span></button>
  </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {

  },
  methods: {
    disableAddingWaypointsMode() {
      this.$store.commit('waypoints/toggleWaypointAddingNotification');
    },
  },
});

</script>

<style scoped>
  .add-mode{
    padding: 1.5em;
    top: 75px;
    left: 1em;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.80);;
    z-index: 101;
    border-radius: 10px;
  }
</style>
