<template>
  <div class="scroll-container">
    <div class="scroll-content">
      <img
        v-for="(photo, index) in photos"
        :key="index"
        :src="photo"
        class="photo"
      />
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style>
.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-content {
  display: inline-block;
}

.photo {
  display: inline-block;
  max-height: 200px;
  margin-right: 10px;
}
</style>
